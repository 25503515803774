import React from 'react';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
import FormPage from '../../templates/FormPage';

const host = process.env.GATSBY_HOST;

const sales = {
  title: 'unsubscribeTitle',
  subTitle: 'unsubscribeText',
  form: {
    title: 'formUnsubscribeTitle',
    type: 'unsubscribe',
    fields: [
      {
        type: 'unsubscribe',
        label: '',
        list: [
          {
            key: 'releaseNotes',
            label: 'formUnsubscribeReleaseNotes',
            value: true,
          },
          {
            key: 'news',
            label: 'formUnsubscribeNews',
            value: true,
          },
          {
            key: 'useCases',
            label: 'formUnsubscribeUseCases',
            value: true,
          },
          {
            key: 'unsubscribed',
            label: 'formUnsubscribed',
            value: false,
          },
        ],
        value: ''
      },
    ],
    button: {
      text: 'formSendUnsubscribe',
      link: `${host}/api/1/json/public/513644/6d85995d468d2cd7d26930393473dff049842412`,
    }
  }
};

class Sales extends React.PureComponent {
  render() {
    return (
      <Layout>
        <FormPage data={sales} />
      </Layout>
    );
  }
}

export default withIntl(Sales);

